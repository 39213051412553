@import "~bootstrap/scss/bootstrap";
@import "./container.css";
@import "./themes.sass";
@import "./themes.sass";
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap');

@font-face {
  src: url('../fonts/MananaSeraBonito-Regular.ttf'); }

main.App {
	background-color: var(--bg__primary);
	color: var(--color__text);
	font-family: 'Open Sans', sans-serif; }
//commons
.container {
	@media (min-width: 1400px) {
		max-width: 1536px !important;
		padding: 0 !important; } }
img {
	border-radius: 8px; }
.ld--title {
	font-weight: 600;
	font-size: 22px;
	line-height: 23px;
	margin-bottom: 32px;
	font-family: 'Open Sans', sans-serif;
	min-height: 23px;
	display: flex;
	gap: 23px;
	align-content: center;
	flex-wrap: wrap; }

.ld--card {
	background: var(--bg__card);
	border-radius: 8px;
	width: 100%;
	height: 100%;
	@media (min-width:576px) {
		padding: 32px; }
	@media (max-width:575px) {
		padding: 16px; }
	ul {
		padding-left: 18px;
		display: flex;
		gap: 23px;
		flex-direction: column; } }

.ld--btn {
	background: var(--bg__btn);
	color: var(--color__text__btn);
	border-radius: 4px;
	overflow: hidden;
	padding: 0;
	border: none;
	min-height: 44px;
	&:disabled {
		cursor: not-allowed; }
	&.disabled {
		background: var(--bg__btn__disabled); }

	span {
		display: block;
		padding: 10px 16px;
		transition: all ease-in-out .2s;
		font-size: 16px;
		font-weight: 600;
		&:hover {
			background: rgba(#000,.2); } } }

.ld--btn-shop {
	display: block;
	padding: 10px 16px;
	transition: all ease-in-out .2s;
	font-size: 16px;
	border-radius: 4px;
	font-weight: 600;
	border: solid 1px;
	border-color: var(--bg__btn);
	background: none;
	color: var(--bg__btn);
	display: block;
	text-decoration: none;
	width: max-content;
	&:hover {
		color: var(--bg__btn); } }

.ld--btn-help {
	transition: all ease-in-out .2s;
	font-size: 16px;
	border-radius: 4px;
	font-weight: 600;
	min-width: 375px;
	text-align: center;
	background: none;
	background: var(--bg__btn__help);
	display: block;
	text-decoration: none;
	width: max-content;
	margin: 55px auto;
	overflow: hidden;
	border: solid 1px var(--bg__btn);
	span {
		color: var(--bg__btn);
		display: block;
		padding: 10px 16px; }
	&:hover {
		span {
			color: var(--bg__btn);
			background: rgba(#000,.2); } } }
//commons end

.App {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.ld--header {
		display: block;
		background-color: #F8F8F8;
		position: relative;
		.urlBannerMovil {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			position: relative;
			margin: 0;
			@media (min-width:769px) {
				display: none; }
			@media (max-width:768px) {
				// min-height: 570px
				display: block; } }
		.urlBanner {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			position: relative;
			margin: 0;
			@media (min-width:769px) {
				display: block; }
			@media (max-width:768px) {
				display: none; } }
		.fixed-top {
			left: 0;
			position: absolute;
			top: 0;
			transition: all .2s ease-in-out;
			width: 100%;
			.container-custom-tm {
				padding-top: 18px;
				padding-bottom: 18px;
				@media (max-width: 600px) {
					padding-top: 8px !important;
					display: flex;
					justify-content: space-between;
					padding-top: 18px;
					padding-bottom: 18px; } }
			svg {
				cursor: pointer; }
			&:hover {
				opacity: 0.7; } }
		.content-bottom {
			position: absolute;
			bottom: 25px;
			left: 0;
			width: 100%;
			z-index: 999;
			.wrap-button {
				display: flex;
				width: 100%;
				justify-content: flex-end;
				span.info {
					height: 54px;
					line-height: 20px;
					border-radius: 8px;
					display: flex;
					background: rgba(#fff, .4 );
					font-size: 15px;
					font-weight: 800;
					padding: 5px 20px;
					margin-right: 10px;
					align-items: center;
					color: var(--color__text); }
				button.share {
					height: 54px;
					width: 54px;
					background: #fff;
					border-radius: 8px;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer; } } }
		figure {
			display: block;
			margin-bottom: 0; }
		img {
			display: block;
			width: 100%;
			display: block;
			border-radius: 0; }

		#modalShare {
			background: rgba(#000,.2);
			.modal-content {
				margin: 20px 10px; } }

		#modalShare .conten-share {
			position: relative;

			& svg {
				cursor: pointer;
				margin-top: 20px;
				display: block;
				margin-right: 20px;
				position: absolute;
				right: 0; }

			& .content {
				max-width: 520px;
				margin: 60px auto;
				padding: 0 10px;
				margin-top: 20px; }

			& .content .title {
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				margin-bottom: 35px; }

			& .content .redes {
				text-align: center;
				margin-bottom: 20px; }

			& .content .redes > div {
				display: inline-block;
				margin: 0 5px 20px; }

			& .content .link {
				margin: 0 auto;
				font-family: var(--font); }

			& .content .link span {
				display: block;
				font-weight: bold;
				font-size: 12px;
				color: rgb(184, 191, 206);
				margin-bottom: 10px;
				cursor: pointer; }

			& .content .link label {
				border-radius: 2px;
				background: rgb(242, 246, 255);
				color: rgb(0, 177, 255);
				font-weight: bold;
				font-size: 14px;
				cursor: pointer;
				padding-left: 20px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: baseline; }

			& .content .link label button {
				background: var(--c1) !important;
				border: none;
				padding: 10px;
				border-radius: 3px;
				color: var(--bg__btn);
				float: right; } } } }

.wrap-countDown {
	display: flex;
	flex-direction: column;
	background: var(--bg__card);
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 2rem;
	&>p {
		font-size: 16px;
		text-align: center;
		color: #909090; }
	.counters {
		display: flex;
		width: 100%;
		justify-content: center;
		div {
			display: flex;
			flex-direction: column;
			margin: 0 10px;
			span:first-child {
				height: 85px;
				line-height: 85px;
				border-radius: 8px;
				background: #fff;
				width: 62px;
				margin-bottom: 10px;
				text-align: center;
				font-size: 35px;
				font-weight: 600; }
			span:last-child {
				color: var(--color__text);
				display: block;
				text-align: center;
				font-size: 12px; } } } }

.theme--dark .wrap-countDown {
	background: var(--bg__card);
	.counters {
		div {
			span:first-child {
				background: var(--bg__btn); } } } }
.ld--intro {
	height: 100%;
	p {
		line-height: 22px; }
	@media (min-width:576px) {
		font-size: 17px; }
	@media (max-width:575px) {
		font-size: 15px; } }

.ld--wrap-notas {
	height: 100%;
	.ld--title {
		/* color: #fff */ }
	li {
		span {
			color: var(--color__text); } } }

.ld--wrap-funciones {
	ld--title {}
	@media (max-width:575px) {
		margin-bottom: 0px !important; }
	.ld--wrap-botones-titles {
		align-items: center;
		display: flex;
		margin-bottom: 30px;
		h1 {
			margin-bottom: 0; }
		.ld--monedas {
			display: flex;
			background: var(--color__text__light);
			border-radius: 4px;
			margin-bottom: 0;
			padding: 3px;
			.hide-on-verysmall {
				padding: 0 !important;
				@media (min-width:411px) {
					display: inline-block; }
				@media (max-width:410px) {
					display: none; } }
			&>span {
				display: flex;
				align-items: center;
				font-weight: 600;
				border-radius: 4px;
				cursor: pointer;
				padding: 0px 10px;
				height: 24px;
				span {
					display: inline-flex;
					line-height: 12px; }
				@media (min-width:576px) {
					font-size: 14px; }
				@media (max-width:575px) {
					padding: 0 5px; }
				&.active {
					background: var(--bg__btn);
					color: #fff; } } } }
	.ld--funciones {
		.ld--card {
			margin-bottom: 30px; }
		.ld--item-funcion {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0; }
			.ld--equipos {
				display: flex;
				align-items: center;
				min-width: 215px;
				img {
					display: block;
					max-width: 100px;
					&:nth-child(2) {
						width: 30px;
						margin: 0 30px; } }
				.ld--ciudad {
					font-size: 20px;
					font-weight: 600;
					line-height: 24px; } }
			.ld--lugar {
				margin-right: 40px;
				min-width: 200px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				.ld--recinto {
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					line-height: 20px; } }
			.ld--fecha {
				font-size: 15px;
				line-height: 20px;
 }				// color: var(--color__text__light)
			.ld--puertas {
				font-size: 14px;
				line-height: 20px;
				color: var(--color__text__light); }

			.ld--acciones {
				margin-left: 40px;
				display: flex;
				align-items: center;
				flex-direction: column;
				button {
					min-width: 140px; }
				a {
					display: block;
					margin-top: 10px;
					font-weight: 600;
					color: var(--color__secondary); } }

			@media (max-width:992px) {
				flex-wrap: wrap;
				.ld--lugar,.ld--fecha,.ld--acciones, {
					width: 100%;
					margin-right: 0; }

				.ld--recinto {
					text-align: center;
					margin: 30px 0; }
				.ld--fecha {
					margin: 20px 0;
					text-align: center; }
				.ld--equipos {
					justify-content: center;
					width: 100%;
					margin-right: 0; }
				.ld--acciones {
					margin-left: 0;
					button {
						width: 100%; } } } } }
	.ld-det-mapa-prec {
		border-top: solid 1px #F2F2F2;
		img {
			display: block;
			width: 100%; } } }

.ld--wrap-precios {
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 100%;
	.ld--precios {
		height: 100%;
		flex-direction: column;
		display: flex;
		width: 100%;
		@media (max-width:767px) {}
		.ld--mapa {
			margin-bottom: 32px;
			display: flex;
			align-items: center;
			figure {
				padding: 50px;
				margin-bottom: 0;
				img {
					display: block;
					max-width: 100%; } } }

		.ld--detalles {
			gap: 16px;
			display: flex;
			flex-direction: column;
			@media (max-width:767px) {}
			.ld--item-precio {
				display: flex;
				justify-content: flex-start;
				box-shadow: 0 0 5px rgba(0,0,0,.1);
				position: relative;
				padding: 16px;
				padding-left: 0;
				align-items: center;
				gap: 10px;
				border-radius: 8px;
				padding: 20px;
				@media (max-width:767px) {
					flex-direction: column; }
				.ld--color {
					height: 12px;
					width: 12px;
					border-radius: 100%;
					background: #aaa;
					display: inline-flex; }
				.ld--info {
					display: inline-flex;
					gap: 10px;
					align-items: center;
					.ld--nombre {
						font-size: 17px;
						line-height: 22px;
						font-weight: 500;
						display: inline-flex;
 }						// white-space: nowrap
					.ld--descripcion {
						font-size: 15px;
						line-height: 20px;
						color: var(--color__text__light); } }
				.ld--precio {
					font-size: 17px;
					line-height: 22px;
					font-weight: 500;
					flex-direction: column;
					@media (min-width:769px) {
						margin-left: auto; } } } }

		.ld--nota-fees {
			font-size: 15px;
			line-height: 23px;
			font-weight: 400;
			color: var(--color__text); } } }

.ld--wrap-prods {
	width: 100%;
	display: flex;
	h5 {
		font-size: 12px;
		margin-bottom: 20px; }
	ul {
		max-height: 200px;
		overflow-y: auto; } }

.ld--wrap-gallery {
	.swiper-button-prev, .swiper-button-next {
		background: rgba(#000, .4 );
		border-radius: 8px;
		height: 70px;
		margin-top: -50px; }
	.swiper-button-prev::after, .swiper-button-next::after {
		font-size: 30px;
		color: #fff; }
	.imgGal {
		width: 100%;
		display: block;
		// max-height: 260px !important
		// max-width: 462px !important
		object-fit: cover;
		aspect-ratio: 1 / .565;
		@media (max-width: 990px) {
			// max-height: 260px !important
 } }			// max-width: 462px !important
	.swiper-pagination {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		.buttonsGallery {
			display: block;
			width: 23px;
			height: 4px;
			background: rgba(#24292E, .2);
			cursor: pointer;
			&:not(:last-child) {
				margin-right: 10px; }
			&.swiper-pagination-bullet-active {
				background: var(--bg__btn); } } } }

.ld--snacks {
	margin-bottom: 32px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch; }

.ld--merch {
	margin-bottom: 32px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch; }

.ld--item-shop {
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 17px;
	.ld--item-wrap {
		width: 100%;
		height: 100%;
		max-width: 700px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px 20px;
		figure {
			display: block;
			margin-right: 40px;
			img {
				display: block;
				width: 200px;
				margin: 0 auto;
				@media (max-width:1200px) {
					width: 150px; } } } }

	.ld--info-item-shop {
		height: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		.ld--nombre {
			font-size: 22px;
			line-height: 28px;
			font-weight: 700;
			margin-bottom: 20px; }
		.ld--promo {
			font-size: 17px;
			margin-bottom: 20px;
			line-height: 22px;
			color: var(--color__text__light);
			strong {
				color: var(--color__text); }
			span {
				text-decoration: line-through; } }
		.ld--btn-shop {} } }
.ld--standing {
	.ld--card {
		margin-top: 32px; }
	.ld--table {
		table {
			width: 100%;
			thead {
				th {
					font-size: 20px;
					padding: 5px 20px;
					text-align: center;
					color: #fff; } }
			tbody {
				td {
					padding: 10px;
					font-size: 15px;
					text-align: center; } } } } }
.videoresumen {
	display: flex;
	flex-direction: column;
	iframe {
		border-radius: 14px;
		width: 100%; } }

.player-wrapper {
	position: relative;
	padding-top: 56.25%; // 720 / 1280 = 0.5625 */
	border-radius: 12px;
	overflow: hidden; }
.react-player__preview {
	border-radius: 8px; }
.react-player {
	position: absolute;
	top: 0;
	left: 0; }
.ld--patrocinantes {
	padding: 24px 0;
	background: #393939;
	h3 {
		color: #B7B7C2;
		text-align: center;
		font-size: 15px;
		line-height: 20px;
		font-weight: 400; }
	.ld--logos {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		width: 100%;
		justify-content: center;
		align-items: center;
		figure {
			margin-bottom: 20px;
			padding: 0 15px;
			max-width: 164px;
			img {
				max-height: 120px;
				display: block;
				width: 100%; } } } }

.ld--footer {
	margin-top: auto;
	background: var(--bg__footer);
	padding: 20px 0;
	span {
		img {
			max-height: 60px;
			margin-right: 20px; } }
	&>div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (max-width:765px) {
			flex-direction: column;
			justify-content: center;
			gap: 10px;
			>span {
				margin: 0 auto;
				img {
					margin: 10px; } } }
		.ld--logo-tm {
			@media (min-width:766px) {
				margin-left: auto; } } } }

// modal
.swal2-modal {
	width: 100% !important;
	max-width: 800px !important;
	.ld--modal-map {
		padding: 15px;
		figure {
			margin-bottom: 0;
			img {
				display: block;
				max-width: 100%; } } } }

.ld--footer {
	margin-top: auto;
	background: #1e1e1e;
	padding: 20px 0;
	img {
		max-height: 60px; }
	&>div {
		display: flex;
		justify-content: space-between;
		.ld--logo-tm {
			max-height: 70px;
			@media (min-width:768px) {
				margin-left: auto; } } } }

.border-radius-10 {
	border-radius: 10px; }
.pd-4 {
	padding: 64px; }
.mb-0 {
	margin-bottom: 0px !important; }
.ld--punto {
	list-style: none;
	flex-wrap: wrap;
	flex-direction: row !important;
	overflow: auto;
	max-height: 500px;
	.ld--card {
		padding: 10px 20px !important;
		background: #fff;
		@media (min-width:766px) {
			width: calc(50% - 12px); }
		ul {
			padding-left: 0 !important;
			list-style: none;
			background: #fff;
			li {
				font-size: 15px;
				line-height: 23px;
				font-weight: 600;
				.des--time {
					display: block;
					color: var(--color__text__light);
					font-weight: 400; } } } } }
.ld--metodo {
	list-style: none;
	flex-wrap: wrap;
	flex-direction: row !important;
	padding-left: 0px !important;
	li {
		width: 100%; }
	.ld--metodo--title {
		font-size: 15px;
		font-weight: 700;
		border-bottom: 1px solid rgba(36,41,46,0.20);
		line-height: 23px;
		padding-bottom: 12px;
		margin-bottom: 16px; }
	.ul_metodo {
		flex-direction: row !important;
		flex-wrap: wrap; }
	ul {
		list-style: none;
		padding-left: 0px !important;
		li {
			display: flex;
			gap: 14px;
			width: calc(50% - 12px); } }
	.img--container {
		width: 48px;
		height: 27px;
		display: flex;
		background: white;
		justify-content: center;
		border-radius: 4px;
		align-items: center; } }
.suport-btn {
	color: white;
	border-left: 1px solid white;
	padding: 6px 16px;
	@media (max-width:765px) {
		border-left: none;
		font-size: 16px;
		font-weight: 600;
		line-height: 40px; }
	&:hover {
		color: var(--bg__btn); } }
.span_productor {
	display: block;
	font-weight: 600;
	font-size: 20px; }
.produtore_list {
	display: flex;
	gap: 30px;
	align-items: center;
	img {
		max-width: 80px;
		height: auto;
		border-radius: 0 !important; } }
.ld--patrocinado {
	h5 {
		font-size: 12px;
		font-weight: 600;
		line-height: 23px; }
	.swiper-button-prev, .swiper-button-next {
		background: rgba(#000, .4 );
		border-radius: 8px;
		height: 70px;
		margin-top: -34px; }
	.swiper-button-prev::after, .swiper-button-next::after {
		font-size: 30px;
		color: #fff; }
	.swiper {
		padding: 0 50px;
		background: #fff;
		border-radius: 8px; }
	.swiper-slide {
		text-align: center;
		padding: 22px 12px;
		img {
			max-width: 100%;
			max-height: 62px; } } }

.ld--wrap-terminos {
	max-width: 100%;
	.content {
		max-height: 200px;
		overflow-y: auto; } }

.modal-dialog {
	max-width: 600px; }
.ld--card--mpa {
	.swiper-button-prev, .swiper-button-next {
		background: rgba(#000, .4 );
		border-radius: 8px;
		height: 70px;
		margin-top: -34px; }
	.swiper-button-prev::after, .swiper-button-next::after {
		font-size: 30px;
		color: #fff; }
	.swiper {
		padding: 0 50px;
		background: #fff;
		border-radius: 8px; }
	.swiper-slide {
		padding-right: 10px;
		text-align: center;
		min-height: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 11px;
		line-height: 1;
		font-weight: 400;
		gap: 5px;
		img {
			max-width: 100%; } } }
.swiper-wrapper {
	.swiper-slide {
		color: #000; } }

